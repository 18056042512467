import styled from '@emotion/styled'

import { mq } from '@/style-theme/media-queries'

export const Column = styled.div(({ theme }) => ({
  flex: 1,
  marginTop: 40,
  paddingLeft: theme.spacings['m'],
  paddingRight: theme.spacings['m'],
  [mq.large]: {
    marginTop: 0,
  },
}))
