/* eslint-disable @next/next/no-img-element */
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { NextPage } from 'next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { Grid } from '@/components/baseUI/Grid'
import { BodyLarge } from '@/components/baseUI/Typography/BodyText'
import { Heading } from '@/components/baseUI/Typography/Heading'
import { Hr } from '@/components/Hr'
import { CheckCircle } from '@/components/icon/CheckCircle'
import { LinkButton } from '@/components/LinkButton'
import { OamCertification } from '@/components/OamCertification'
import { Page } from '@/components/Page'
import { Column } from '@/components/PageStructure/Column'
import { ColumnHeadingWrapper } from '@/components/PageStructure/ColumnHeadingWrapper'
import { Row } from '@/components/PageStructure/Row'
import { useDevice } from '@/context/device-context'
import { mq } from '@/style-theme/media-queries'
import { CtaFlow } from '@components/CtaFlow'
import { SquigglyLine } from '@components/SquigglyLine'
import { sendInteractionEvent } from '@utils/tracking/eventHandlers'

const Homepage: NextPage = () => {
  const device = useDevice()

  const router = useRouter()
  const { utm_source } = router.query

  const metaDescription =
    'Il mutuo è più semplice grazie alla consulenza gratuita di Casavo Mutui. Ti alleggeriamo della burocrazia, così puoi dedicarti alla cosa che conta davvero: casa tua.'

  const theme = useTheme()

  useEffect(() => {
    if (utm_source) {
      /*
        If the user comes from the simulator widget placed on an external web page, save the utm_source
        in session storage so if the user becomes a lead during the current session we can track its
        origin to the specific widget.
      */
      const source = Array.isArray(utm_source) ? utm_source[0] : utm_source
      sessionStorage.setItem('utm_source', source)
    }
  })

  return (
    <Page transparentHeader metaDescription={metaDescription} metaTitle="Home">
      <Hero>
        <Grid>
          <div
            css={{
              alignItems: 'center',
              display: 'flex',
              paddingBottom: 40,
              [mq.large]: {
                paddingBottom: 0,
                textAlign: 'left',
              },
              textAlign: 'center',
            }}
          >
            <div
              css={(theme) => ({
                display: 'none',
                padding: `0 ${theme.spacings.xl}px`,
                [mq.large]: { display: 'block' },
              })}
            >
              <Image alt="" height={183} quality={100} src={'/img/illustrations/bank_loan@2x.png'} width={443} />
            </div>
            <div>
              <Heading
                css={(theme) => ({
                  marginTop: theme.spacings['3xl'],
                  [mq.large]: {
                    marginTop: theme.spacings['4xl'],
                  },
                })}
                level={'display'}
              >
                Il mutuo, ma senza stress.
              </Heading>
              <BodyLarge
                css={(theme) => ({
                  marginTop: theme.spacings['l'],
                })}
              >
                E senza scartoffie. Così puoi dedicarti alle cose che ami davvero: la tua casa.
              </BodyLarge>
              <div css={(theme) => ({ marginTop: theme.spacings.l, [mq.large]: { marginTop: theme.spacings.xl } })}>
                <CtaFlow />
              </div>
            </div>
          </div>
        </Grid>
      </Hero>
      <section css={{ padding: '90px 0', [mq.large]: { padding: '100px 0' } }}>
        <IllustrationWrapper>
          <SquigglyLine background={theme.colors.offWhite} />
        </IllustrationWrapper>
        <Grid css={{ textAlign: 'center' }}>
          <Heading level={2}>
            Guida semplificata al mutuo. <br />E cosa fa Casavo Mutui per te.
          </Heading>
          <Row>
            <Column>
              <div css={{ height: 120, position: 'relative' }}>
                <Image alt="" height={123} quality={100} src="/img/illustrations/loan_guide.png" width={123} />
              </div>
              <BodyLarge
                css={(theme) => ({
                  marginTop: theme.spacings['l'],
                  [mq.xlarge]: {
                    padding: `0 ${theme.spacings['m']}px`,
                  },
                })}
              >
                Devi scegliere il finanziamento. E noi ti aiutiamo a trovare il più adatto per te.
              </BodyLarge>
            </Column>
            <Column>
              <div css={{ height: 120, position: 'relative' }}>
                <Image alt={''} height={123} quality={100} src="/img/illustrations/documents_loan.png" width={123} />
              </div>
              <BodyLarge
                css={(theme) => ({
                  marginTop: theme.spacings['l'],
                  [mq.xlarge]: {
                    padding: `0 ${theme.spacings['m']}px`,
                  },
                })}
              >
                Devi controllare la documentazione. Lo facciamo noi per te.
              </BodyLarge>
            </Column>
            <Column>
              <div css={{ height: 120, position: 'relative' }}>
                <Image alt={''} height={123} quality={100} src="/img/illustrations/support_loan.png" width={123} />
              </div>
              <BodyLarge
                css={(theme) => ({
                  marginTop: theme.spacings['l'],
                  [mq.xlarge]: {
                    padding: `0 ${theme.spacings['m']}px`,
                  },
                })}
              >
                Devi parlare e riparlare con la banca. Ci pensiamo noi, così tu non perdi tempo.
              </BodyLarge>
            </Column>
          </Row>
        </Grid>
      </section>
      <section css={{ paddingBottom: 100, textAlign: 'center' }}>
        <Grid>
          <Hr />
          <Heading css={(theme) => ({ marginTop: theme.spacings['4xl'] })} level={2}>
            Lo sappiamo: richiedere un mutuo stanca.
          </Heading>
          <div
            css={{
              margin: '0 auto',
              marginTop: 40,
              maxWidth: 826,
            }}
          >
            <BodyLarge>
              Per questo nasce Casavo Mutui, un servizio per rendere semplici le cose più complesse. Perché quando devi
              richiedere un mutuo devi conoscere il tasso di interesse giusto, capire se conviene fisso o variabile,
              confrontarti con la banca, controllare ogni singolo documento e sperare che l&apos;annuncio della casa che
              hai visto non sparisca. Ora non dovrai più preoccuparti di nulla: pensiamo a tutto noi.
            </BodyLarge>
          </div>
        </Grid>
      </section>
      <OamCertification />
      <section css={(theme) => ({ padding: `${theme.spacings['4xl']}px 0`, [mq.large]: { padding: '90px 0' } })}>
        <Grid>
          <Heading
            css={(theme) => ({
              [mq.large]: { paddingLeft: 0, textAlign: 'center' },
              paddingLeft: theme.spacings['m'],
            })}
            level={2}
          >
            Come funziona? <br />
            Te lo spieghiamo passo per passo.
          </Heading>
          <Row>
            <Column>
              <Heading
                as={'h3'}
                css={(theme) => ({
                  color: theme.colors.greyscale['600'],
                })}
                level={4}
              >
                <ColumnHeadingWrapper>
                  <CheckCircle />
                  Prima fai il preventivo.
                </ColumnHeadingWrapper>
              </Heading>
              <BodyLarge
                css={(theme) => ({
                  marginTop: theme.spacings['s'],
                  [mq.large]: {
                    marginTop: theme.spacings['m'],
                  },
                })}
              >
                Così scopri subito l&apos;importo della rata. Bastano 3 step e pochi dati per richiedere il preventivo.
                Ed è gratuito.
              </BodyLarge>
            </Column>
            <Column>
              <Heading
                as={'h3'}
                css={(theme) => ({
                  color: theme.colors.greyscale['600'],
                })}
                level={4}
              >
                <ColumnHeadingWrapper>
                  <CheckCircle />
                  Poi prenoti la consulenza.
                </ColumnHeadingWrapper>
              </Heading>
              <BodyLarge
                css={(theme) => ({
                  marginTop: theme.spacings['s'],
                  [mq.large]: {
                    marginTop: theme.spacings['m'],
                  },
                })}
              >
                Valutiamo insieme la soluzione migliore per te, così non dovrai preoccuparti di nulla. Bello, vero?
              </BodyLarge>
            </Column>
            <Column>
              <Heading
                as={'h3'}
                css={(theme) => ({
                  color: theme.colors.greyscale['600'],
                })}
                level={4}
              >
                <ColumnHeadingWrapper>
                  <CheckCircle />E siamo sempre con te.
                </ColumnHeadingWrapper>
              </Heading>
              <BodyLarge
                css={(theme) => ({
                  marginTop: theme.spacings['s'],
                  [mq.large]: {
                    marginTop: theme.spacings['m'],
                  },
                })}
              >
                Per chiarire dubbi, paure e preoccupazioni fino all&apos;erogazione del mutuo. Perché le cose più belle,
                sono quelle semplici.
              </BodyLarge>
            </Column>
          </Row>
        </Grid>
      </section>
      <section
        css={(theme) => ({
          backgroundColor: theme.colors.greyscale[0],
          padding: '70px 0 100px 0',
          textAlign: 'center',
          [mq.large]: {
            padding: '70px 0 160px 0',
          },
        })}
      >
        <Grid>
          <Heading as={'h2'} level={1}>
            Calcoliamo la rata del mutuo, insieme?
          </Heading>
          <BodyLarge
            css={(theme) => ({
              margin: '0 auto',
              marginTop: theme.spacings['l'],
              maxWidth: 650,
            })}
          >
            E in pochi click puoi conoscere l&apos;importo della tua rata e chiedere una consulenza ai nostri esperti.
          </BodyLarge>
          <SimulatorIllustration alt="" height={100} src="/img/illustrations/calculator.png" width={100} />
          <LinkButton
            colorVariant="dark"
            css={{ margin: '48px auto 0 auto', maxWidth: 310 }}
            href={'/come-possiamo-aiutarti'}
            styleVariant="primary"
            onClick={() =>
              sendInteractionEvent({
                device,
                event: 'ClickAskForQuoteButton',
                interaction_target: 'AskForQuoteButton',
                interaction_type: 'Click',
              })
            }
          >
            Richiedi un preventivo gratuito
          </LinkButton>
        </Grid>
      </section>
    </Page>
  )
}
export default Homepage

const Hero = styled.section(({ theme }) => ({
  backgroundColor: theme.colors.lightBlue,
  color: theme.colors.greyscale[600],
  padding: `${theme.spacings['2xl']}px 0`,
  [mq.large]: {
    padding: `${theme.spacings['4xl']}px 0`,
  },
}))

const SimulatorIllustration = styled.img({
  display: 'none',
  [mq.large]: {
    display: 'block',
    position: 'absolute',
  },
})

const IllustrationWrapper = styled.div({
  position: 'absolute',
  right: 10,
  top: 480,
  [mq.medium]: { top: 385 },
  [mq.large]: { right: 25, top: 575 },
  [mq.xlarge]: { right: 100, top: 515 },
})
