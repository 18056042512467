/* eslint-disable @next/next/no-img-element */
import { Grid } from '@/components/baseUI/Grid'
import { BodyLarge } from '@/components/baseUI/Typography/BodyText'
import { Heading } from '@/components/baseUI/Typography/Heading'
import { mq } from '@/style-theme/media-queries'

export const OamCertification: React.FC = () => (
  <section
    css={(theme) => ({
      backgroundColor: theme.colors.deepGreen['600'],
      color: theme.colors.greyscale[0],
      padding: `${theme.spacings['2xl']}px 0`,
      [mq.large]: {
        padding: '60px 0',
      },
    })}
  >
    <Grid css={{ textAlign: 'center' }}>
      <Heading as={'h3'} level={4}>
        Casavo Mutui è sicuro
      </Heading>
      <div css={(theme) => ({ marginTop: theme.spacings['2xl'] })}>
        <a
          css={{ display: 'inline-block' }}
          href="https://www.organismo-am.it/b/0/11849430969/b4de92d3-0e1f-4d4f-85ee-758fb4c78e71/g.html"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="sr-only">Verifica lo stato dell&apos;autorizzazione ad operare OAM per Casavo Mutui.</span>
          <img
            alt=""
            css={{ margin: '0 auto', maxWidth: 127 }}
            src="https://www.organismo-am.it/b/0/7T9BYW6ct_sl_63Uw3YVEgvJQK7gLJGldfe4tfdSReo_pl_CQ_eq_.png"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = '/img/oam-cert-fallback.png'
            }}
          />
        </a>
      </div>
      <BodyLarge
        css={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 40,
          maxWidth: 500,
        }}
      >
        Casavo Mutui è un servizio autorizzato e vigilato dall’OAM, l&apos;Organismo per la gestione degli Elenchi degli
        Agenti in attività finanziaria e dei Mediatori creditizi.
      </BodyLarge>
    </Grid>
  </section>
)
