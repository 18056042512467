import styled from '@emotion/styled'
import React from 'react'

import { LinkButton } from '@/components/LinkButton'
import { useDevice } from '@/context/device-context'
import { mq } from '@/style-theme/media-queries'
import { sendInteractionEvent } from '@/utils/tracking/eventHandlers'

type Props = {
  className?: string
  target?: string
}

export const CtaFlowWrapper = styled.div({
  margin: '24px auto 0 auto',
  [mq.large]: {
    margin: '70px auto 0 auto',
  },
})

export const CtaFlow: React.FC<Props> = ({ className, target }) => {
  const device = useDevice()

  return (
    <LinkButton
      className={className}
      colorVariant="dark"
      href="/come-possiamo-aiutarti"
      styleVariant="primary"
      target={target}
      onClick={() =>
        sendInteractionEvent({
          device,
          event: 'ClickAskForQuoteButton',
          interaction_target: 'AskForQuoteButton',
          interaction_type: 'Click',
        })
      }
    >
      Richiedi il mutuo senza stress
    </LinkButton>
  )
}
