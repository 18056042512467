import { IconProps } from './types'

export const CheckCircle: React.FC<IconProps> = ({
  dataTestId,
  fillColor,
  height = 32,
  viewBox = '0 0 38 38',
  width = 32,
}) => {
  return (
    <svg
      css={{ alignSelf: 'baseline' }}
      data-testid={dataTestId}
      fill="none"
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" fill={fillColor ? fillColor : 'currentColor'} r="17.5" stroke="white" strokeWidth="3" />
      <path
        clipRule="evenodd"
        d="M26.1311 11L27.8889 12.5888L16.3426 27L10.1111 21.4357L11.5963 19.5592L16.071 23.5543L26.1311 11Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  )
}
