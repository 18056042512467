import styled from '@emotion/styled'

import { mq } from '@/style-theme/media-queries'

export const Row = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacings['s'],
  [mq.large]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 56,
  },
}))
